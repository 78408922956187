'use strict';

import http from '@/utils/http';

export default {
  // 奖品分页
  lotteryUrl: '/prize-category/page',
  // 新增资料
  add(code, data) {
    return http.post('/prize-category', data, { code });
  },
  // 编辑资料
  update(code, data) {
    return http.put('/prize-category', data, { code });
  },
  // 删除资料
  delete(code, params) {
    return http.delete('/prize-category', { params, code });
  },
  audit(code, data) {
    return http.put('/prize-category/audit', data, { code });
  },
  push(code, data) {
    return http.get('/prize-category/prizeDrawById?id=' + data.id);
  },
  // 查询单条
  getfindById(code, params) {
    return http.get(`/prize-category/${params.id}`);
  },
  lotteryPublish(code, data) {
    return http.post('/prize-category/prizeDraw', data, {
      code,
    });
  },
};
