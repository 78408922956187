<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        allow-selection
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #receiveConsult="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
        <!-- todo 需要补齐  -->
        <template #level="{ record }">
          <div v-if="record.level === 1">一级</div>
          <div v-else-if="record.level === 2">二级</div>
          <div v-else>三级</div>
        </template>
        <template #publishStatus="{ record }">
          <div v-if="record.publishStatus === 0">未发布</div>
          <div v-else-if="record.publishStatus === 1">已发布</div>
        </template>
        <template #auditStatus="{ record }">
          <div v-if="record.auditStatus === 0">未审核</div>
          <div v-else-if="record.auditStatus === 1">已审核</div>
        </template>
        <template #photoUrl="{ record }">
          <img :src="record" />
        </template>

        <!--      首页显示状态  ("首页展示状态 1:已展示  0:为展示")   -->
        <template #showStatus="{ record }">
          <a-switch
            :checked="record.showStatus"
            checked-children="启用"
            un-checked-children="禁用"
            @click="handleClickSwitch(record)"
          />
        </template>
      </grid>
    </div>
  </div>

  <iss-edit
    v-model:visible="visible"
    v-model:confirmLoading="confirmLoading"
    :init-value="activeItem"
    @fnOk="handleFnOkByEdit"
  />
</template>

<script>
import { reactive, ref, toRefs, h } from 'vue';
import { Tag, Switch, message, modal } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
// import contactApi from '@/api/contact.js';
import lotteryApi from '@/api/lottery.js';
import issEdit from './components/edit';
// import { EyeOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    ATag: Tag,
    issEdit,
    // EyeOutlined,
    ASwitch: Switch,
  },
  setup() {
    const gridRef = ref();
    const store = useStore();
    const state = reactive({
      visible: false,
      search: {},
      // TagList: [],
      activeItem: {},
    });
    const switchChecked = ref(false);
    const router = useRouter();
    // contactApi.getTag('', {}).then(list => {
    //   list?.forEach(item => {
    //     state.TagList.push({ value: item.tagCode, label: item.tagName });
    //   });
    // });

    return {
      gridRef,
      store,
      ...toRefs(state),
      items: [
        { key: 'lotteryName', label: '抽奖名称' },
        {
          key: 'pushStatus',
          label: '发布状态',
          type: 'select',
          dataset: [
            { value: '1', label: '已发布' },
            { value: '0', label: '未发布' },
          ],
        },
        {
          key: 'approvalStatus',
          label: '审核状态',
          type: 'select',
          dataset: [
            { value: '1', label: '已审核' },
            { value: '0', label: '未审核' },
          ],
        },
      ],
      columns: [
        {
          dataIndex: 'lotteryName',
          title: '抽奖名称',
          width: 160,
          ellipsis: true,
        },
        {
          dataIndex: 'level',
          title: '等级',
          width: 100,
          ellipsis: true,
          slots: { customRender: 'level' },
        },
        {
          dataIndex: 'lotteryNum',
          title: '奖品数量',
          width: 160,
          ellipsis: true,
        },
        {
          dataIndex: 'auditStatus',
          title: '审核状态',
          width: 160,
          ellipsis: true,
          slots: { customRender: 'auditStatus' },
        },
        {
          dataIndex: 'publishStatus',
          title: '发布状态',
          width: 160,
          ellipsis: true,
          slots: { customRender: 'publishStatus' },
        },
        {
          dataIndex: 'publishTime',
          title: '发布时间',
          width: 240,
          ellipsis: true,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 168,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'classify:add',
          fnClick: () => {
            state.visible = true;
            state.activeItem = {};
          },
        },

        // todo 发布接口
        {
          type: 'isPublish',
          label: '发布',
          icon: 'ExportOutlined',
          permission: 'lottery:publish',
          fnClick: () => {
            lotteryApi.lotteryPublish('', '').then(() => {
              message.success('发布成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],
      options: [
        {
          type: 'update',
          label: '奖品',
          permission: 'lottery:update',
          fnClick: record => {
            let id = record.id;
            router.push({ name: 'lotteryPrize', params: { id } });
          },
        },
        {
          type: 'config',
          label: '编辑',
          icon: 'SettingTwoTone',
          permission: 'lottery:config',
          fnClick: record => {
            state.visible = true;
            state.activeItem = { ...record };
            let id = record.id;
            lotteryApi.getfindById('', { id }).then(res => {
              state.activeItem = { ...res };
            });
          },
        },
        {
          type: 'approval',
          label: '审核',
          icon: 'FileProtectOutlined',
          permission: 'lottery:approval',
          fnClick: record => {
            //todo modal.confirm
            switchChecked.value = record.auditStatus === 1 ? true : false;
            modal.confirm({
              title: '审核',
              centered: true,
              content: h(Switch, {
                checked: switchChecked,
                'checked-children': '通过',
                'un-checked-children': '拒绝',
                onChange: value => {
                  switchChecked.value = value;
                },
              }),
              onOk: () => {
                console.log(switchChecked.value);
                //todo 调接口审核
                let pramate = {
                  id: record.id,
                  auditStatus: switchChecked.value ? 1 : 0,
                };
                lotteryApi.audit('', pramate).then(() => {
                  message.success('操作成功');
                  gridRef.value.refreshGrid();
                });
                //重置switchChecked
                // switchChecked.value = false;
              },
              onCancel: () => {
                switchChecked.value = false;
              },
            });
          },
        },
        {
          type: 'push',
          label: '发布',
          icon: 'SendOutlined',
          permission: 'lottery:push',
          fnClick: record => {
            //todo modal.confirm
            modal.confirm({
              title: '发布',
              centered: true,
              content: h('div', [
                h('p', `是否确认发布${record.name || ''}？`),
                h('p', '已发布的奖品不可再次编辑。'),
              ]),
              onOk: () => {
                //todo 调接口发布
                let id = record.id;
                console.log('发布' + id);
                let pramate = {
                  id: id,
                };
                lotteryApi.push('', pramate).then(() => {
                  message.success('操作成功');
                  gridRef.value.refreshGrid();
                });
                //重置switchChecked
              },
              onCancel: () => {
                console.log('push/cancel');
              },
            });
            // state.visible = true;
            // state.activeItem = { ...record };
            let id = record.id;
            console.log(id);
          },
        },
        {
          type: 'delete',
          permission: 'resources:delete',
          fnClick: ({ id }) => {
            console.log('id', id);
            lotteryApi.delete('resources:delete', { id: id }).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],
      url: lotteryApi.lotteryUrl,
      gridHeight: document.body.clientHeight - 386,
      // { id, showStatus }
      handleClickSwitch() {
        // showStatus = !showStatus;
        // todo 需要联调首页显示状态
        // lotteryApi.updateAutoSwitch('', { id, showStatus }).then(res => {
        //   if (res) {
        //     message.success('操作成功');
        //     gridRef.value.refreshGrid();
        //   }
        // });
      },

      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        state.search = temp;
      },
      handleFnOkByEdit: value => {
        let type = 'add';
        const { id } = value;
        id && (type = 'update');

        lotteryApi[type](`stand:${type}`, value).then(() => {
          message.success('操作成功');
          state.visible = false;
          gridRef.value.refreshGrid();
          setTimeout(() => {
            state.confirmLoading = false;
          }, 1500);
        });
        // 保存失败后 恢复保存按钮
        setTimeout(() => {
          state.confirmLoading = false;
        }, 1500);
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}
.contact-page {
  & .title {
    .right {
      i {
        margin-left: 3px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
