<template>
  <a-modal
    :visible="visible"
    :title="form.id ? '编辑' : '新增'"
    :mask-closable="false"
    centered
    :width="600"
    class="model"
    @ok="handleOk"
    style="top: 20px"
    @cancel="handleCancel"
  >
    <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="抽奖名称" v-bind="validateInfos.lotteryName">
        <a-input
          v-model:value="form.lotteryName"
          placeholder="请输入抽奖名称"
        />
      </a-form-item>
      <a-form-item label="等级" v-bind="validateInfos.level">
        <a-select v-model:value="form.level" placeholder="请选择等级">
          <a-select-option
            v-for="item in levelList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ref, reactive, toRaw, toRefs, watch } from 'vue';
import { Form, Select } from 'ant-design-vue';
// import { useRoute } from 'vue-router';
// import agendaApi from '@/api/agenda';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    // const route = useRoute();
    // const campaignId = route.params.campaignId;
    // const editorRef = ref();
    const required = { required: true, message: '不能为空' };
    const form = reactive({
      id: '',
      lotteryName: '',
      level: '',
    });
    const levelList = ref([
      {
        id: 1,
        name: '一级',
      },
      {
        id: 2,
        name: '二级',
      },
      {
        id: 3,
        name: '三级',
      },
    ]);
    const state = reactive({});
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      lotteryName: [required, { max: 30, message: '抽奖名称不能超过30个字符' }],
      // level: [required],
    });

    watch(
      () => props.initValue,
      initValue => {
        if (initValue.id) {
          Object.assign(form, initValue);
        } else {
          resetFields();
        }
      }
    );
    return {
      ...toRefs(state),
      form,
      validateInfos,
      levelList,
      handleCancel: () => context.emit('update:visible', false),
      handleOk: () => {
        validate().then(() => {
          context.emit('fnOk', toRaw(form));
        });
      },
    };
  },
};
</script>
<style scoped>
/* .model {
  max-height: 600px;
} */
</style>
